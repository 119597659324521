.UHTBanner {
    position: relative;
    /* z-index: -10; */
    height: 100vh;
    display: flex;
    align-items: center;
    object-fit: contain;
    top: 2rem;
    width: 100%;
}

.UHTBanner > .bg-image {
    background: url("../images/home-bg.jpg") 100% no-repeat;
    background-position: top;
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -2;
}

.UHTBanner .title {
    text-align: left;
    font-size: 38px;
    font-weight: 800;
    color: #7c58f2;
    margin-bottom: 1rem;
    line-height: 1;
}

.UHTBanner .title span.Medi{
    font-weight: 800;
    color: #1a76d1;
    font-size: 60px;
}

.UHTBanner .title span.Genie{
    font-weight: 800;
    color: #f47321;
    font-size: 60px;
}

.UHTBanner .subtitle {
    text-align: left;
    font-size: 22px;
    color: #2c2d3f;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 1.5rem;
}

.UHTBanner .subtitle span{
    color: #592c82;
    font-weight: 700;
}

.UHTBanner .btn-book-demo {
    color: #fff;
    background: #0970b8 !important;
    border: 1px solid #0970b8 !important;
    font-size: 18px !important;
    padding: 12px 30px !important;
    border-radius: 30px !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

.UHTBanner .btn-book-demo:hover {
    color: #fff;
    background: #7c58f2 !important;
    border: 1px solid #7c58f2!important;
}

.UHTBanner .btn-saving-calculator {
    color: #7c58f2;
    background: #fff !important;
    border: 1px solid #2b328f !important;
    font-size: 18px !important;
    padding: 12px 30px !important;
    border-radius: 30px !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

.UHTBanner .btn-saving-calculator:hover {
    color: #fff;
    background: #7c58f2 !important;
    border: 1px solid #7c58f2 !important;
}

.banner-goqii-btn{
    line-height: normal;
    display: flex
;
    align-items: center;
    width: fit-content;
}


.UHTBanner .btn-goqii-anchor {
    color: #fff;
    background: #7c58f2 !important;
    border: 1px solid #7c58f2 !important;
    font-size: 18px !important;
    padding: 12px 30px !important;
    border-radius: 30px !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

.UHTBanner .btn-goqii-anchor:hover {
    color: #fff;
    background: #0970b8 !important;
    border: 1px solid #0970b8!important;
}

@media screen and (max-width: 980px) {
    .UHTBanner {
        position: relative;
        height: 86vh;
        display: flex;
        align-items: flex-end;
        object-fit: contain;
        top: 0em;
        width: 100%;
        text-align: center;
    }
    .UHTBanner .title {
        text-align: center;
        font-size: 35px;
        font-weight: 800;
        color: #7c58f2;
        margin-bottom: 1rem;
        line-height: 1;
    }
    .UHTBanner .subtitle {
        text-align: center;
        font-size: 18px;
        color: #2c2d3f;
        margin-bottom: 1rem;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 1.5rem;
    }
    .UHTBanner > .bg-image {
        background: url("../images/home-bg-m.jpg") 100% no-repeat;
        background-position: bottom;
        background-size: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
    }

    .UHTBanner .btn-book-demo {
        color: #fff;
        background: #0970b8 !important;
        border: 1px solid #0970b8 !important;
        font-size: 16px !important;
        padding: 10px 20px !important;
        border-radius: 30px !important;
        line-height: 1.2 !important;
        font-weight: 700 !important;
    }

    .UHTBanner .btn-book-demo:hover {
        color: #fff;
        background: #7c58f2 !important;
        border: 1px solid #7c58f2!important;
    }

    .UHTBanner .btn-saving-calculator {
        color: #7c58f2;
        background: #fff !important;
        border: 1px solid #2b328f !important;
        font-size: 16px !important;
        padding: 10px 20px !important;
        border-radius: 30px !important;
        line-height: 1.2 !important;
        font-weight: 700 !important;
    }

    .UHTBanner .btn-saving-calculator:hover {
        color: #fff;
        background: #7c58f2 !important;
        border: 1px solid #7c58f2 !important;
    }
}