
.footer-light {
    z-index: 2;
    background-position: 0 0;
    background-size: auto;
    padding: 45px 30px;
    /* position: relative; */
    background-color: #f2f7fd;
  }
  
  .container-2 {
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .footer-wrapper-two {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }
  
  .footer-brand {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  
  .footer-block-two {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
  }
  
  .footer-title {
    color: #646464;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  
  .footer-link-two {
    color: #646464;
    margin-top: 14px;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
  }
  
  .footer-link-two:hover {
    color: #1a1b1fbf;
  }
  
  .footer-form {
    width: 315px;
    max-width: 100%;
    margin-bottom: 0;
  }
  
  .footer-form-block {
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    display: flex;
  }
  
  .footer-form-field {
    color: #071c3c;
    background-color: #fff0;
    border: 2px solid #e4e5e5;
    border-radius: 9999px;
    width: 100%;
    height: 48px;
    margin-bottom: 0;
    padding: 30px 16px 30px 24px;
    font-size: 14px;
    line-height: 22px;
    transition: all .2s;
  }
  
  .footer-form-field:hover, .footer-form-field:focus {
    border-color: #76879d;
  }
  
  .footer-form-field::placeholder {
    color: #071c3c;
     
    font-size: 16px;
    line-height: 22px;
  }
  
  .footer-form-submit {
    background-color: #004a88;
    background-image: url("../images/telegram.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto;
    border-radius: 99px;
    width: 50px;
    height: 48px;
    transition: all .2s;
    position: relative;
    right: 55px;
  }
  
  .footer-form-submit:hover {
    background-color: #3a4554;
  }
  
  .footer-divider-two {
    background-color: #cacaca;
    width: 100%;
    height: 1px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .footer-bottom {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  
  .footer-copyright {
    color: #646464;
    font-size: 18px;
    line-height: 24px;
  }
  
  .footer-social-block-two {
    grid-column-gap: 23px;
    grid-row-gap: 23px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    display: flex;
  }
  
  .footer-social-link {
    margin-left: 0;
  }
  
  .paragraph.footer-small {
    color: #6f6f6f;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.w-layout-hflex {
    flex-direction: row;
    align-items: flex-start;
    display: flex;
  }
  
  .w-layout-vflex {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }
  
  @media screen and (max-width: 980px) {
      .footer-light {
          padding-left: 15px;
          padding-right: 15px;
      }    
      .footer-wrapper-two {
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
      }
      .footer-block-two {
        margin-top: 30px;
      }
      .footer-title {
        color: #646464;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
      }
      
      .footer-link-two {
        color: #646464;
        margin-top: 10px;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none;
      }
      .footer-copyright {
        color: #646464;
        font-size: 15px;
        line-height: 24px;
    }
  }