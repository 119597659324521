.UHTeModulePopup {
    background-color: #ffffff;
    height: 100%;
}

.UHTeModulePopup .challenge-title {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #2c2d3f;
    margin-bottom: 0.5rem;
}

.UHTeModulePopup .challenge-subtitle {
    text-align: center;
    font-size: 20px;
    color: #505050;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.5;
}


.UHTeModulePopup .main-title {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: #2c2d3f;
    margin-bottom: 0rem;
}
.UHTeModulePopup .main-title span{
  font-size: 48px;
  color: #1a4aa3;
}

.UHTeModulePopup .content-text {
  text-align: left;
  font-size: 15px;
  color: #797979;
  margin-bottom: 0rem;
  font-weight: 600;
}

.UHTeModulePopup .content-text1 {
    text-align: left;
    font-size: 15px;
    color: #797979;
    margin-bottom: 0rem;
    font-weight: 400;
}

.UHTeModulePopup .btn-calculator{
    color: #fff;
    background: #0970b8 !important;
    border: 1px solid #0970b8 !important;
    font-size: 18px !important;
    padding: 12px 30px !important;
    border-radius: 30px !important;
    line-height: 1.2 !important;
    font-weight: 700 !important;
}

.UHTeModulePopup .btn-calculator:hover {
    color: #fff;
    background: #7c58f2 !important;
    border: 1px solid #7c58f2!important;
}

.UHTeModulePopup .calculator-box {
  background-color: #d5e4ea;
  border-radius: 20px;
  padding: 30px;
  /* height: 260px; */
}
