.UHTHealthcare {
    background-color: #ffffff;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.UHTHealthcare .healthcare-title {
    font-size: 65px;
    color: rgba(124, 88, 242, 0.8);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    font-weight: 800;
    margin-bottom: 2.5rem;
}
.UHTHealthcare .healthcare-subtitle {
    text-align: left;
    font-size: 35px;
    color: #4e4e4e;
    margin-bottom: 1rem;
    font-weight: 800;
}

.UHTHealthcare .content-text {
    text-align: left;
    font-size: 19px;
    color: #2c2d3f;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.UHTHealthcare .content-text span {
    color: #592c82;
    font-weight: 700;
}

.UHTHealthcare .grey-box {
    border-radius: 40px;
    background-color: rgb(246, 246, 246);
    overflow: hidden;
}
.UHTHealthcare .grey-box img{
    border-radius: 40px;
}

.custom-prev-healthacare,
.custom-next-healthacare {
  position: absolute;
  right: 70px; /* Place navigation on the left */
  bottom: 30px;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  z-index: 10;
  color: #000; /* Arrow color */
}

.custom-next-healthacare {
  right: 30px; /* Offset the next arrow */
}

.custom-swiper-healthacare .swiper-slide {
    opacity: 0; /* Start invisible */
    transition: opacity 1s ease-in-out; /* Smooth fade-in/out */
  }
  
  .custom-swiper-healthacare .swiper-slide-active {
    opacity: 1; /* Visible slide */
}

@media screen and (max-width: 980px) {
    .UHTHealthcare .healthcare-title {
        font-size: 35px;
        color: rgba(124, 88, 242, 0.8);
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        font-weight: 800;
        margin-bottom: 1.5rem;
    }
    .UHTHealthcare .healthcare-subtitle {
        text-align: center;
        font-size: 25px;
        color: #4e4e4e;
        margin-bottom: 1rem;
        font-weight: 800;
    }
    .UHTHealthcare .grey-box {
        border-radius: 20px;
        background-color: rgb(246, 246, 246);
        overflow: hidden;
    }
    .UHTHealthcare .grey-box img{
        border-radius: 20px;
    }
    .UHTHealthcare .grey-box .px-5 {
        padding: 2rem !important;
    }
    .UHTHealthcare .content-text {
        text-align: center;
        font-size: 18px;
        color: #2c2d3f;
        margin-bottom: 1rem;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 1rem;
    }
    .custom-prev-healthacare,
    .custom-next-healthacare {
        position: absolute;
        right: 90%; /* Place navigation on the left */
        bottom: auto;
        top: 67%;
        transform: translateY(-50%);
        font-size: 24px;
        font-weight: bold;
        cursor: pointer;
        z-index: 10;
        color: #000; /* Arrow color */
    }

    .custom-next-healthacare {
        right: 5%; /* Offset the next arrow */
    }
    
}