.UHTPartners {
    background-color: #ffffff;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.UHTPartners .partners-title {
    font-size: 65px;
    color: rgba(124, 88, 242, 0.8);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    font-weight: 800;
    margin-bottom: 1rem;
}
.UHTPartners .partners-subtitle {
    text-align: center;
    font-size: 35px;
    color: #4e4e4e;
    margin-bottom: 1.5rem;
    font-weight: 800;
}

.UHTPartners .content-text {
    text-align: center;
    font-size: 22px;
    color: #2c2d3f;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.UHTPartners .content-text span {
    color: #592c82;
    font-weight: 700;
}
@media screen and (max-width: 980px) {
    .UHTPartners .partners-title {
        font-size: 35px;
        color: rgba(124, 88, 242, 0.8);
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        font-weight: 800;
        margin-bottom: 1rem;
    }
    .UHTPartners .partners-subtitle {
        text-align: center;
        font-size: 25px;
        color: #4e4e4e;
        margin-bottom: 1.5rem;
        font-weight: 800;
    }
    
    .UHTPartners .content-text {
        text-align: center;
        font-size: 18px;
        color: #2c2d3f;
        margin-bottom: 1rem;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 1rem;
    }
}