.UHTNavbar {
    width: 100%;
    margin: 0 auto;
    border-radius: 30px;
    background-color: rgb(255 255 255 / 00%) !important;
    position: fixed !important;
    top: 0;
    z-index: 9999; /* Ensure the navbar is on top of other content */
  }

  nav.navbar.sticky-top.navbar-expand-lg.bg-body-tertiary.UHTNavbar {
    background-color: transparent !important;
}
  
  .UHTNavbar-inner {
    width: 100%;
    margin: 0 auto;
    border-radius: 30px;
    padding: 10px 20px !important;
    background-color: #f1f1f1 !important;
  }
  
  .UHTNavbar .navbar-brand img {
    height: 42px; /* Adjust the size of your logo */
  }
  
  /* Ensure navbar items are aligned to the right */
  .UHTNavbar .navbar-nav {
    margin-left: auto; /* This pushes the nav items to the right */
  }
  
  .UHTNavbar .navbar-nav .nav-link-menu {
    font-size: 19px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
    display: block;
    cursor: pointer;
  }

  .UHTNavbar .navbar-nav .nav-link-menu:hover {
    color: #7c58f2;
    text-decoration-color: #7c58f2 !important;
  }