.UHTValue {
    background-color: #ffffff;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.UHTValue .value-title {
    font-size: 65px;
    color: rgba(124, 88, 242, 0.8);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    font-weight: 800;
    margin-bottom: 0.5rem;
}
.UHTValue .value-subtitle {
    text-align: left;
    font-size: 35px;
    color: #4e4e4e;
    margin-bottom: 1rem;
    font-weight: 800;
}

.UHTValue .content-text {
    text-align: left;
    font-size: 19px;
    color: #2c2d3f;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.UHTValue .content-text span {
    color: #592c82;
    font-weight: 700;
}

.custom-prev,
.custom-next {
  position: absolute;
  left: 0px; /* Place navigation on the left */
  bottom: 6%;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  z-index: 10;
  color: #000; /* Arrow color */
}

.custom-next {
  left: 40px; /* Offset the next arrow */
}

.custom-swiper .swiper-slide {
    opacity: 0; /* Start invisible */
    transition: opacity 1s ease-in-out; /* Smooth fade-in/out */
  }
  
  .custom-swiper .swiper-slide-active {
    opacity: 1; /* Visible slide */
  }


@media screen and (max-width: 980px) {
    .UHTValue .value-title {
        font-size: 35px;
        color: rgba(124, 88, 242, 0.8);
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        font-weight: 800;
        margin-bottom: 0.5rem;
    }
    .UHTValue .value-subtitle {
      text-align: center;
        font-size: 25px;
        color: #4e4e4e;
        margin-bottom: 1rem;
        font-weight: 800;
    }
    .UHTValue .content-text {
      text-align: -webkit-center;
      font-size: 18px;
      color: #2c2d3f;
      margin-bottom: 1rem;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 1rem;
  }
  .custom-prev, .custom-next {
      position: absolute;
      left: 0px;
      bottom: 22%;
      transform: translateY(-50%);
      font-size: 24px;
      font-weight: bold;
      cursor: pointer;
      z-index: 10;
      color: #000;
  }
  .custom-next {
      left: 94%;
  }
}