.UHTAdvisors {
    background-color: #ffffff;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.UHTAdvisors .advisors-title {
    font-size: 65px;
    color: rgba(124, 88, 242, 0.8);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    font-weight: 800;
    margin-bottom: 1rem;
}
.UHTAdvisors .advisors-subtitle {
    text-align: center;
    font-size: 35px;
    color: #4e4e4e;
    margin-bottom: 1.5rem;
    font-weight: 800;
}

.UHTAdvisors .content-text {
    text-align: center;
    font-size: 22px;
    color: #2c2d3f;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.UHTAdvisors .content-text span {
    color: #592c82;
    font-weight: 700;
}

.advisor {
    grid-column-gap: 41px;
    grid-row-gap: 41px;
    background-color: #f2f7fd;
    background-image: url("../images/image-206.png");
    background-size: cover;
    border-radius: 20px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 230px;
    max-width: 350px;
    height: 250px;
    padding: 64px;
    display: flex;
  }
  
  .advisor.yat-siu {
    justify-content: flex-start;
    align-items: flex-end;
    width: 300px;
    height: 300px;
  }
  
  .advisor.vishal-gondal {
    background-image: url("../images/image-208.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 300px;
    height: 300px;
  }
  
  .advisor.zeneca {
    background-image: url("../images/image-2010.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 300px;
    height: 300px;
  }
  
  .advisor.agatya-saman {
    background-image: url("../images/6186161131983322452.jpg");
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 250px;
  }
  
  .advisor.kavita-gupta {
    background-image: url("../images/image-209.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }
  
  .advisor.tarum-katial {
    background-image: url("../images/image-2011.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }
  
  .advisor.lauren-selig {
    background-image: url("../images/image-2012.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }
  
  .advisor.manish-agarwal {
    background-image: url("../images/image-2013.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }
  
  .advisor.manish-dureja {
    background-image: url("../images/image-2014.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }
  
  .advisor.manoj-narenden-madnadi {
    background-image: url("../images/image-2015.png");
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }
  
  .advisor.mohammed-ezeldin {
    background-color: #f2f7fd00;
    background-image: url("../images/frame-201000009348.png");
    background-position: 50% 0;
    background-size: auto;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
  }
  
  .advisor-item {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border-radius: 20px;
    flex-flow: column;
    width: 250px;
    padding-bottom: 16px;
    display: flex;
    box-shadow: 0 4px 13px #0000000d;
  }
  
  .advisor-item.core {
    width: 300px;
    height: auto;
    position: static;
  }
  
  .flex-block-3 {
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .advisor-wrapper {
    grid-column-gap: 35px;
    grid-row-gap: 35px;
    align-items: stretch;
    height: auto;
    padding-top: 38px;
    padding-bottom: 38px;
    line-height: 20px;
  }
  
  .advisor-wrapper.core {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    height: auto;
    padding-top: 61px;
    padding-bottom: 61px;
  }
  
  .advisor-name {
    font-weight: 600;
  }


.linkedin {
    background-image: url("../images/vector.svg");
    background-position: 0 0;
    background-size: cover;
    width: 40px;
    height: 40px;
    position: relative;
    top: -121px;
    left: 45px;
  }
  

  .flex-block-5 {
    grid-column-gap: 0;
    grid-row-gap: 0;
    align-items: center;
    justify-content: flex-start;
  }
  .w-layout-vflex1 {
    flex-direction: column;
  }
  
  .w-layout-hflex1, .w-layout-vflex1 {
    /* align-items: center; */
    display: flex
  ;}
  
  .linked-in-core {
    background-image: url("../images/vector.svg");
    background-position: 0 0;
    background-size: cover;
    width: 40px;
    height: 40px;
    position: relative;
    inset: -21% 0% auto 40px;
  }
  .paragraph.black.center-allign.semi-large {
    font-size: 24px;
}
.paragraph.black.center-allign {
    font-weight: 600;
    text-align: center;
}
.paragraph.black {
    color: #000;
    text-align: left;
}
.paragraph {
    color: #646464;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.flex-block-3 {
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-top: 20px;
}
.w-layout-vflex1 {
    flex-direction: column;
    align-items: center;
    display: flex
;
}

.w-layout-hflex1, .w-layout-vflex1 {
    /* align-items: center; */
    display: flex
;
}

.investors-logo.goqii {
    padding-top: 17px;
    padding-bottom: 17px;
  }
@media screen and (max-width: 980px) {
    .UHTAdvisors .advisors-title {
        font-size: 35px;
        color: rgba(124, 88, 242, 0.8);
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        font-weight: 800;
        margin-bottom: 1rem;
    }
    .UHTAdvisors .advisors-subtitle {
        text-align: center;
        font-size: 25px;
        color: #4e4e4e;
        margin-bottom: 1.5rem;
        font-weight: 800;
    }
    
    .UHTAdvisors .content-text {
        text-align: center;
        font-size: 18px;
        color: #2c2d3f;
        margin-bottom: 1rem;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 1rem;
    }
    .container.partner-section {
        grid-column-gap: 36px;
        grid-row-gap: 36px;
        padding-top: 0;
    }

    .w-layout-hflex1 {
        flex-direction: column;
    align-items: flex-start;
    display: flex
;
    }
    .w-layout-vflex1 {
        flex-direction: column;
        align-items: center;
        display: flex
    ;
    }
}