.UHTVision {
    background-color: #ffffff;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.UHTVision .vision-title {
    font-size: 65px;
    color: rgba(124, 88, 242, 0.8);
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    font-weight: 800;
    margin-bottom: 0.5rem;
}
.UHTVision .vision-subtitle {
    text-align: left;
    font-size: 35px;
    color: #4e4e4e;
    margin-bottom: 1rem;
    font-weight: 800;
}

.UHTVision .content-text {
    text-align: left;
    font-size: 22px;
    color: #2c2d3f;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.UHTVision .content-text span {
    color: #592c82;
    font-weight: 700;
}

.btn-readmore {
    color: #2c2d3f !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    font-size: 16px !important;
    padding: 6px 12px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
    text-transform: uppercase;
}

.btn-readmore:hover {
    color: #fff !important;
    background: #333 !important;
    border: 1px solid #333 !important;
}

.play-video-box{
    background-color: #8a8a8a;
    border-radius: 20px;
    padding: 20px;
    height: revert-layer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 260px;
}

@media screen and (max-width: 980px) {
    .UHTVision .vision-title {
        font-size: 35px;
        color: rgba(124, 88, 242, 0.8);
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        font-weight: 800;
        margin-bottom: 0.5rem;
    }
    .UHTVision .vision-subtitle {
        text-align: -webkit-center;
        font-size: 25px;
        color: #4e4e4e;
        margin-bottom: 1rem;
        font-weight: 800;
    }
    .UHTVision .content-text {
        text-align: center;
        font-size: 18px;
        color: #2c2d3f;
        margin-bottom: 1rem;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 1rem;
    }
}